<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
  html,
  body,
  div,
  ul,
  li,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  div,
  img,
  form,
  strong,
  input,
  hr {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-decoration: none;
  }
  html,body,#app {
  	height:100%;
  }
</style>
