import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import apiurl from '../src/utils/imgurl'
Vue.prototype.apiurl = apiurl

import '@/utils/eventBus'
Vue.directive("lazy", function(el, binding) {
	let oldSrc = binding.value
	el.src = ""
	let lazyLoadObser = new IntersectionObserver((entries, observer) => {
		entries.forEach((entry, index) => {
			let lazyImage = entry.target
			if (entry.intersectionRatio == 1) {
				el.src = oldSrc
			}
		})
	})
	lazyLoadObser.observe(el)
});

import {
	storage,
	session
} from '@/utils/cookie'
Vue.prototype.$cookieStore = {
	storage,
	session
}

// storage.get('name')	// 获取
// storage.set('name', '白羽')	// 设置
// storage.remove('name')	// 移除


router.beforeEach((to, from, next) => {
	// 路由发生变化修改页面title
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')