import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'login',
		meta: {
			title: '登录'
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/login')
	},
	{
		path: '/index',
		name: 'index',
		meta: {
			title: 'DIY页面编辑'
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/index')
	},
	{
		path: '/detail',
		name: 'detail',
		meta: {
			title: '预览'
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/detail')
	}
]

const router = new VueRouter({
	routes
})

export default router